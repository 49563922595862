import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/contex";
import Pagination2 from "../../components/Pagination2";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Container, Carousel, Tab, Tabs, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/new.css";
import Header from "../../components/header";
import axios from "axios";
import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 showAlert,
 searchArray,
 searchArrayBool,
 generateNonce,
} from "../../utils/helpers";
import Picker from "emoji-picker-react";
import Footer from "../../components/footer";
import Slider from "../../components/Slider";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import Post from "../../components/Post";
import SuggestionsSidebar from "../../components/recommended";
import Sidebar from "../../components/sidebar";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

const chunkArray = (array, size) => {
 const result = [];
 for (let i = 0; i < array.length; i += size) {
  result.push(array.slice(i, i + size));
 }
 return result;
};

const Timeline = () => {
 const [type, setType] = useState("all");
 const [comments, setComments] = useState({});
 const [emojiPickers, setEmojiPickers] = useState({});
 const [allPosts, setAllPosts] = useState([]);
 const [videoPosts, setVideoPosts] = useState([]);
 const [photoPosts, setPhotoPosts] = useState([]);
 const [freeVideos, setFreeVideos] = useState([]);
 const [trendingVideos, setTrendingVideos] = useState([]);
 const [newestVideos, setNewestVideos] = useState([]);

 const [creditGift, setCreditGift] = useState({});

 const [allPagination, setAllPagination] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [photoPagination, setPhotoPagination] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [videoPagination, setVideoPagination] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const {
  address,

  isConnected,
  tokenBalance,
  apiURL,
  user,
  setLoading,
  settings,
  withdraw,
  domain,
  tokenPrice,
  getUser,
 } = useContext(Context);

 const carouselItems = [
  {
   id: 1,
   src: "assets/images/timeline-slides/1.png",
   alt: "Influencer 1",
   caption: "Subscribe to Influencer 1",
  },
  {
   id: 2,
   src: "assets/images/timeline-slides/2.png",
   alt: "Influencer 2",
   caption: "Exclusive Content by Influencer 2",
  },
  {
   id: 3,
   src: "assets/images/timeline-slides/3.png",
   alt: "Influencer 3",
   caption: "Join Influencer 3",
  },
 ];

 const getAllPosts = async (vtype = "all", page = 1, limit = 16) => {
  setLoading(true);
  try {
   const details = {
    action: "get_creators_posts",
    page: page,
    limit: limit,
    type: vtype,
   };
   if (isConnected && address) {
    details.user = address;
   }

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error: ", data.error);
   } else {
    if (vtype === "all") {
     setAllPosts(data.records);
     setAllPagination(data.pagination);
    } else if (vtype === "photos") {
     setPhotoPosts(data.records);
     setPhotoPagination(data.pagination);
    } else if (vtype === "videos") {
     setVideoPosts(data.records);
     setVideoPagination(data.pagination);
    }
    console.log("All posts:", data.records);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getAllPosts(type);
 }, [type, address]);

 const getSidePosts = async (vtype = "videos", page = 1, limit = 20) => {
  setLoading(true);
  try {
   const details = {
    action: "get_creators_posts",
    page: page,
    limit: limit,
    type: vtype,
   };
   if (isConnected && address) {
    details.user = address;
   }

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error: ", data.error);
   } else {
    processVideos(data.records);
    console.log("All posts:", data.records);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getSidePosts();
 }, [address]);

 const processVideos = (records) => {
  // 1. Free Videos
  const freeVideos = records.filter((video) => video.payment_type === "free");
  const freeVideosChunked = chunkArray(freeVideos, 4); // Group into arrays of 4
  setFreeVideos(freeVideosChunked);

  // 2. Trending Videos
  const trendingVideos = records
   .filter((video) => video.is_photo === "no")
   .sort((a, b) => b.views - a.views);
  const trendingVideosChunked = chunkArray(trendingVideos, 4);
  setTrendingVideos(trendingVideosChunked);

  // 3. Newest Videos
  const newestVideos = records
   .filter((video) => video.is_photo === "no")
   .sort((a, b) => b.id - a.id);
  const newestVideosChunked = chunkArray(newestVideos, 4);
  setNewestVideos(newestVideosChunked);
 };

 const handleDelete = async (vid) => {
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  const confirmDelete = window.confirm("Are you sure you want to delete this post?");
  if (!confirmDelete) return;

  const formData = new FormData();
  formData.append("vid", vid);
  formData.append("action", "delete_post");

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   if (response.data.success) {
    showAlert({
     title: "Successful!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    await getAllPosts(type);
   } else {
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while deleting the video. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
 };

 const deleteComment = async (id) => {
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  const confirmDelete = window.confirm("Are you sure you want to delete this comment?");
  if (!confirmDelete) return;

  const formData = new FormData();
  formData.append("post_id", id);
  formData.append("action", "delete_comment");

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   if (response.data.success) {
    await getAllPosts(type);
   } else {
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while deleting the video. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
 };

 const handleLike = async (videoId) => {
  if (!videoId || videoId == null) return;
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Connect a wallet to like this post.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  try {
   const details = {
    action: "like_video",
    vid: videoId,
    wallet: address,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (!data.success) {
    // console.error("NO LIKE:", data.message);
   } else {
    getAllPosts(type);
    // setUserFans(data);
    // console.log("LIKED:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 // Handle emoji picker toggle for a specific post
 const toggleEmojiPicker = (postId) => {
  setEmojiPickers((prev) => ({
   ...prev,
   [postId]: !prev[postId],
  }));
 };

 // Handle emoji selection for a specific post
 const onEmojiClick = (postId, event, emojiObject) => {
  const emoji = event.emoji;
  setComments((prev) => ({
   ...prev,
   [postId]: (prev[postId] || "") + emoji,
  }));
 };

 // Handle comment input change for a specific post
 const handleCommentChange = (postId, value) => {
  setComments((prev) => ({
   ...prev,
   [postId]: value,
  }));
 };

 const giftChange = (postId, value) => {
  setCreditGift((prev) => ({
   ...prev,
   [postId]: value,
  }));
 };

 const handleCommentSubmit = async (postId) => {
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (!comments[postId]) {
   return showAlert({
    title: "Error!",
    text: "Please type a comment.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  const formData = new FormData();
  formData.append("post_id", postId);
  formData.append("wallet", address);
  formData.append("comment", comments[postId]);

  formData.append("action", "post_comment");
  //setLoading(true);
  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });
   console.log("COMMENT", response);
   if (response.data.success) {
    await getAllPosts(type);
    setComments((prev) => ({
     ...prev,
     [postId]: "",
    }));
    setEmojiPickers({});
   } else {
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  //setLoading(false);
 };

 const handleSubscribe = async (creator, amount, sidebar = 0) => {
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (+user.credits < +amount) {
   return showAlert({
    title: "ERROR!",
    text: "Insufficient v18plus balance. Top up v18plus and try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (sidebar === 1) {
   const confirm = window.confirm(
    `A monthly subscription fee of ${formatNumber(
     amount
    )} will be debited from your balance. Press Ok to proceed.`
   );
   if (!confirm) return;
  }
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "add_fan",
     creator: creator,
     fan: address,
     amount: amount,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    //await getUserFans(creator);
    await getAllPosts(type);
    await getUser(address);
    showAlert({
     title: "SUCCESSFUL!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    //  setIsFan(true);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 const sendGift = async (id, wallet) => {
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (!creditGift[id]) {
   return showAlert({
    title: "ERROR!",
    text: "Enter v18plus amount.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (+user.credits < +creditGift[id]) {
   return showAlert({
    title: "ERROR!",
    text: "Insufficient v18plus balance. Top up v18plus and try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "gift_credit",
     from: address,
     to: wallet,
     amount: creditGift[id],
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    await getUser(address);
    showAlert({
     title: "SUCCESSFUL!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    //  setIsFan(true);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 const payCreator = async (postId, price, wallet) => {
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }

  if (+user.credits < +price) {
   return showAlert({
    title: "ERROR!",
    text: "Insufficient v18plus balance. Top up v18plus and try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  const confirm = window.confirm(
   `A content price of ${formatNumber(
    price
   )} will be debited from your balance. Press Ok to proceed.`
  );
  if (!confirm) return;
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "pay_for_post",
     from: address,
     to: wallet,
     amount: price,
     post_id: postId,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
    showAlert({
     title: "HEADS UP!",
     text: data.error,
     icon: "warning",
     confirmButtonText: "OK",
    });
   } else {
    await getUser(address);
    await getAllPosts(type);
    showAlert({
     title: "SUCCESSFUL!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 return (
  <>
   <div id="wrapper-container">
    <Header />
    <Slider />
    <Container>
     <Row className="my-5">
      <Col md={8}>
       <Tabs
        defaultActiveKey="all"
        id="justify-tab-example"
        className="mb-3"
        onSelect={(selectedKey) => setType(selectedKey)} // Use onSelect here
       >
        <Tab eventKey="all" title="All">
         <Row className="all-posts-tab">
          <Col>
           <Post
            key="all"
            post={allPosts}
            handleDelete={handleDelete}
            handleLike={handleLike}
            handleCommentChange={handleCommentChange}
            comments={comments}
            deleteComment={deleteComment}
            type="all"
            pages={allPagination}
            toggleEmojiPicker={toggleEmojiPicker}
            handleCommentSubmit={handleCommentSubmit}
            emojiPickers={emojiPickers}
            onEmojiClick={onEmojiClick}
            creditGift={creditGift}
            giftChange={giftChange}
            sendGift={sendGift}
            handleSubscribe={handleSubscribe}
            getAllPosts={getAllPosts}
            payCreator={payCreator}
           />
          </Col>
         </Row>
        </Tab>
        <Tab eventKey="photos" title="Photos">
         <Row>
          <Col>
           <Post
            key="photo"
            post={photoPosts}
            handleDelete={handleDelete}
            handleLike={handleLike}
            handleCommentChange={handleCommentChange}
            comments={comments}
            deleteComment={deleteComment}
            type="photos"
            pages={photoPagination}
            toggleEmojiPicker={toggleEmojiPicker}
            handleCommentSubmit={handleCommentSubmit}
            emojiPickers={emojiPickers}
            onEmojiClick={onEmojiClick}
            creditGift={creditGift}
            giftChange={giftChange}
            sendGift={sendGift}
            handleSubscribe={handleSubscribe}
            getAllPosts={getAllPosts}
            payCreator={payCreator}
           />
          </Col>
         </Row>
        </Tab>
        <Tab eventKey="videos" title="Videos">
         <Row>
          <Col>
           <Post
            key="video"
            post={videoPosts}
            handleDelete={handleDelete}
            handleLike={handleLike}
            handleCommentChange={handleCommentChange}
            comments={comments}
            deleteComment={deleteComment}
            type="videos"
            pages={videoPagination}
            toggleEmojiPicker={toggleEmojiPicker}
            handleCommentSubmit={handleCommentSubmit}
            emojiPickers={emojiPickers}
            onEmojiClick={onEmojiClick}
            creditGift={creditGift}
            giftChange={giftChange}
            sendGift={sendGift}
            handleSubscribe={handleSubscribe}
            getAllPosts={getAllPosts}
            payCreator={payCreator}
           />
          </Col>
         </Row>
        </Tab>
       </Tabs>
      </Col>
      <Col md={4}>
       <Sidebar
        handleSubscribe={handleSubscribe}
        payCreator={payCreator}
        freeVideos={freeVideos}
        trendingVideos={trendingVideos}
        newestVideos={newestVideos}
       />
      </Col>
     </Row>
    </Container>

    <Footer />
   </div>
  </>
 );
};
export default Timeline;
