import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import {
 shortenAddress,
 handleStopAndReset,
 playExplainer,
 formatNumber,
 formatLocalTime,
} from "../../utils/helpers";
import axios from "axios";

import Pagination from "../../components/Pagination";

function Notifications() {
 const [alerts, setAlerts] = useState([]);

 const { apiURL, domain, setLoading, isConnected, address } = useContext(Context);

 const getUserAlerts = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_notifications",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setAlerts(data);
    console.log("ALERTS:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) return;
  getUserAlerts(address);
 }, [address, isConnected]);

 const updateRead = async (receiverId) => {
  // console.log("THE", chat);
  const formData = new FormData();
  formData.append("receiver", receiverId);
  formData.append("action", "update_unread_notification");

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "application/x-www-form-urlencoded",
   },
  });
  // console.log("THE", response);

  if (response.data.success) {
   //setConversation(response.data.messages);
   // setActiveChat(chat);
   console.log("UPDATE", response);
  }
 };

 useEffect(() => {
  if (!address) return;
  updateRead(address);
 }, [address]);

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }} className="pt-5">
     <div className="content-area">
      <div className="notifications-list">
       {alerts.length > 0 ? (
        alerts.map((a, i) => (
         <div key={i} className="notification-item">
          <i className={`fa ${a.icon} notification-icon`} />
          <div className="notification-content">
           <p className="notification-message">{a.text}</p>
           <span className="notification-timestamp">{formatLocalTime(a.date)}</span>
          </div>
         </div>
        ))
       ) : (
        <div className="text-center">
         <img src="assets/images/fly.gif" height={100} />
         <p>No notifications here yet...</p>
        </div>
       )}
      </div>
     </div>
    </div>

    <Footer />
   </div>
  </div>
 );
}

export default Notifications;
