import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context/contex";
import "./style.css";
import SpinnerOverlay from "./Spinner";
import { formatNumber, shortenAddress } from "../utils/helpers";
import { Modal, Container, Form, Button, Image, Row, Col, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const Header = () => {
 const {
  address,
  token,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  user,
  getUser,
  loading,
  setLoading,
  buyCredits,
  domain,
  tokenPrice,
 } = useContext(Context);
 //const [isMenuOpen, setMenuOpen] = useState(false);
 const [openSubMenus, setOpenSubMenus] = useState({});
 const [wallet, setWallet] = useState("");
 const [amount, setAmount] = useState("");
 const [showSearch, setShowSearch] = useState(false);
 const [query, setQuery] = useState("");
 const [results, setResults] = useState([]);
 const [isLoading, setIsLoading] = useState(false);
 const [isSticky, setIsSticky] = useState(false);
 const [unread, setUnread] = useState(0);
 const [unreadAlerts, setUnreadAlerts] = useState(0);

 const [activeSubmenu, setActiveSubmenu] = useState(null);
 const [userFans, setUserFans] = useState([]);
 const [menuOpen, setMenuOpen] = useState(false);

 const toggleMenu = () => setMenuOpen(!menuOpen);
 const closeMenu = () => setMenuOpen(false);
 const toggleSubmenu = (menu) => {
  setActiveSubmenu(activeSubmenu === menu ? null : menu);
 };

 const getUserFans = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_user_fans",
    wallet: wallet,
   };
   if (user.username != null) {
    details.is_username = "yes";
   }
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserFans(data);
    //  console.log("All fans:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) return;
  getUserFans(address);
 }, [address]);

 const addWallet = async (connection, wallet) => {
  if (!connection || !wallet) return;
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "add_user_wallet",
     wallet: wallet,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (!data.added) {
    console.error("Error:", data.message);
   } else {
    getUser(address);
    //  setRelatedVideos(data);
    console.log("Success:", data.message);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  addWallet(isConnected, address);
 }, [isConnected, address]);

 useEffect(() => {
  if (query.length > 0) {
   const fetchData = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("query", query);
    formData.append("type", "all");

    formData.append("action", "search_creators");
    try {
     const response = await axios.post(apiURL, formData, {
      headers: {
       "Content-Type": "application/x-www-form-urlencoded",
      },
     });
     setResults(response.data);
    } catch (error) {
     console.error("Error fetching search results", error);
    }
    setIsLoading(false);
   };

   const debounceTimeout = setTimeout(() => {
    fetchData();
   }, 300); // Adjust the debounce delay as needed

   return () => clearTimeout(debounceTimeout);
  } else {
   setResults([]);
  }
 }, [query]);

 useEffect(() => {
  const handleScroll = () => {
   const triggerPoint = document.querySelector("#start-sticky").offsetTop;

   // Check if scroll position has crossed the trigger point
   if (window.scrollY > triggerPoint) {
    setIsSticky(true);
   } else {
    setIsSticky(false);
   }
  };

  // Attach the scroll event listener
  window.addEventListener("scroll", handleScroll);
  return () => {
   // Clean up the scroll event listener
   window.removeEventListener("scroll", handleScroll);
  };
 }, []);

 useEffect(() => {
  const fetchUnread = async () => {
   if (!isConnected) {
    return;
   }

   const formData = new FormData();
   formData.append("receiver", address);
   formData.append("action", "get_unread_chat");

   try {
    const response = await axios.post(apiURL, formData, {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    });

    if (response.data.success) {
     // console.log("UNREAD:", response.data);

     setUnread(response.data.unread);
    } else {
     // console.log("AUNREAD:", response.data);

     setUnread(0);
    }
   } catch (error) {
    console.error("Error:", error);
   }
  };

  fetchUnread();
  const interval = setInterval(() => {
   fetchUnread();
  }, 3000);
  return () => clearInterval(interval);
 }, [isConnected, address]);

 useEffect(() => {
  const fetchUnreadA = async () => {
   if (!isConnected) {
    return;
   }

   const formData = new FormData();
   formData.append("receiver", address);
   formData.append("action", "get_unread_notifications");

   try {
    const response = await axios.post(apiURL, formData, {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    });

    if (response.data.success) {
     // console.log("UNREAD:", response.data);

     setUnreadAlerts(response.data.unread);
    } else {
     // console.log("AUNREAD:", response.data);

     setUnreadAlerts(0);
    }
   } catch (error) {
    console.error("Error:", error);
   }
  };

  fetchUnreadA();
  const interval = setInterval(() => {
   fetchUnreadA();
  }, 3000);
  return () => clearInterval(interval);
 }, [isConnected, address]);

 return (
  <>
   <SpinnerOverlay loading={loading} />

   <Row className="py-5 new-top-header ">
    <Col md={2}></Col>
    <Col md={8} className="text-center">
     <a href="/" className="logo">
      <img
       src={`${domain}assets/images/modeland-logo.png`}
       alt="IMG"
       style={{ height: "100px", width: "auto" }}
      />
     </a>
    </Col>
    <Col md={2} className="text-center mt-3">
     <div className="text-center">
      <w3m-button balance="hide" />
     </div>
     {isConnected && (
      <div className="right-icons">
       <div
        className="p-1"
        style={{ border: "1px #333 solid", borderRadius: "15px", fontSize: "12px" }}
       >
        {formatNumber(user.credits)} v18plus
       </div>
       <img
        data-toggle="modal"
        data-target="#buyModal"
        data-backdrop="static"
        src="assets/images/svg/plus-circle.svg"
       />
      </div>
     )}
    </Col>
    <Col>
     <div id="start-sticky" />
     <nav className={`new-nft-nav-container ${isSticky ? "fixed-bottom-header" : ""}`}>
      {/* Main Navigation */}
      <ul className="new-nft-nav-menu">
       <li className="new-nft-nav-item new-nft-active">
        <a href="/">HOME</a>
       </li>
       <li className="new-nft-nav-item">
        <a href="https://ai18pluscoin.com/about-us/" target="_blank">
         ABOUT
        </a>
       </li>
       <li className="new-nft-nav-item new-nft-submenu-parent">
        <a href="/creators">CREATORS</a>
        <ul className="new-nft-submenu">
         <li>
          <a href="/become-creator">Become a Creator</a>
         </li>
         <li>
          <a href="/my-ai-models">My Ai Models</a>
         </li>
         <li>
          <a href="/face-swap">Face Swap</a>
         </li>
        </ul>
       </li>
       <li className="new-nft-nav-item">
        <a href="/videos">VIDS</a>
       </li>
       <li className="new-nft-nav-item">
        <a href="/live">LIVE NOW</a>
       </li>
       <li className="new-nft-nav-item new-nft-submenu-parent">
        <a href="/marketplace">MARKETPLACE</a>
        <ul className="new-nft-submenu">
         <li>
          <a href="/onchain-nft">NFT IDs</a>
         </li>
         <li>
          <a href="/profile-nft">Invest in a creator</a>
         </li>
         <li>
          <a href="/create-nft">Mint your NFT</a>
         </li>
        </ul>
       </li>
       <li className="new-nft-nav-item">
        <a href="https://ai18pluscoin.com/" target="_blank">
         COIN18+
        </a>
       </li>
       <li className="new-nft-nav-item">
        <a href="https://blog.modeland.ai/" target="_blank">
         BLOG
        </a>
       </li>
      </ul>

      {/* Icons Section */}
      <div className="new-nft-nav-icons">
       <i className="fa fa-search" onClick={() => setShowSearch(true)}></i>

       <a href="/contact">
        <i className="fa fa-envelope"></i>
       </a>
       {isConnected && (
        <>
         <a href="/notifications">
          <i className="fa fa-bell"></i>
          {unreadAlerts > 0 && (
           <sup>
            <span className="badge badge-danger">{unreadAlerts}</span>
           </sup>
          )}
         </a>
         <a href="/chat">
          <i className="fa fa-commenting"></i>
          {unread > 0 && (
           <sup>
            <span className="badge badge-danger">{unread}</span>
           </sup>
          )}
         </a>

         <a href={`#`} onClick={toggleMenu}>
          <img src={domain + user.profile_photo} style={{ borderRadius: "100%" }} />{" "}
         </a>
        </>
       )}
      </div>
     </nav>
    </Col>
   </Row>

   {/* Overlay */}
   {menuOpen && <div className="overlay" onClick={closeMenu}></div>}

   {/* Side Menu */}
   <div className={`side-menu ${menuOpen ? "open" : ""}`} onClick={closeMenu}>
    <div className="side-menu-content" onClick={(e) => e.stopPropagation()}>
     {/* Header */}
     <div className="side-menu-header d-flex align-items-center">
      {isConnected ? (
       <>
        <img
         src={domain + user.profile_photo}
         alt="Avatar"
         className="rounded-circle menu-avatar"
        />
        <div className="ms-3">
         <h5 className="mb-0">
          {" "}
          {!user.username ? shortenAddress(user.wallet) : user.username}{" "}
          {user.verified === "yes" && (
           <i className="fa fa-check-circle text-primary" aria-hidden="true" />
          )}
         </h5>
        </div>
       </>
      ) : (
       <>
        <img
         src={domain + "assets/images/avatar.svg"}
         alt="Avatar"
         className="rounded-circle menu-avatar"
        />
        <div className="ms-3">
         <h5 className="mb-0">Guest</h5>
        </div>
       </>
      )}
      <i className="fa fa-times ms-auto close-icon" onClick={closeMenu}></i>
     </div>
     {isConnected && (
      <div className="d-flex justify-content-between">
       <div>
        <i className="fa fa-credit-card" aria-hidden="true"></i> {formatNumber(user.credits)}
       </div>
       <div>
        <i className="fa fa-heart-o" aria-hidden="true"></i> Fans {userFans.length}
       </div>
      </div>
     )}
     {/* Menu Items */}
     <ul className="menu-list mt-4">
      <li className="list">
       <a href={`profile?user=${user.username !== null ? user.username : user.wallet}`}>
        View Profile
       </a>
      </li>
      <li className="list">
       <a href="/edit-profile">Edit Profile</a>
      </li>
      <li className="list">
       <a href="/chat">
        My Chats{" "}
        {unread > 0 && (
         <sup>
          <span className="badge badge-danger">{unread}</span>
         </sup>
        )}
       </a>
      </li>
      {user.is_creator === "yes" && (
       <li className="list">
        <a href="/post-fan-video">Create A Post</a>
       </li>
      )}
      <li className="list">
       <i className="fa fa-envelope-o"></i> <a href="/contact">CONTACT</a>
      </li>
     </ul>
    </div>
   </div>

   {/*BUY CREDITS modal*/}
   <div
    className="modal fade"
    id="buyModal"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="buyModalLabel"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-md" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <h3 className="modal-title" id="buyModalLabel">
        Buy v18plus for self or friends
       </h3>
       <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <div className="mb-2">
        <input
         type="text"
         style={{
          background: "#111",
          height: "50px",
          color: "#fff",
          width: "100%",
         }}
         placeholder="Paste recipient's wallet"
         onInput={(e) => setWallet(e.target.value)}
         value={wallet}
        />
       </div>

       <div>
        <input
         type="number"
         style={{
          background: "#111",
          height: "50px",
          color: "#fff",
          width: "100%",
         }}
         placeholder="Amount"
         onInput={(e) => setAmount(e.target.value)}
         value={amount}
        />
       </div>
      </div>

      <div className="modal-footer">
       <button type="button" className="btn btn-secondary" data-dismiss="modal">
        I'm done
       </button>
       {isConnected ? (
        <button type="button" className="btn btn-custom" onClick={() => buyCredits(wallet, amount)}>
         Pay {amount && formatNumber(amount)} tokens
        </button>
       ) : (
        <button disabled={true} type="button" className="btn btn-custom">
         Connect your wallet first.
        </button>
       )}
      </div>
     </div>
    </div>
   </div>
   <Modal show={showSearch} onHide={() => setShowSearch(false)} backdrop="static">
    <Modal.Header closeButton>
     <Modal.Title className="text-white">Search Creators</Modal.Title>
    </Modal.Header>
    <Modal.Body className="text-white">
     <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
      <Form.Label>Paste creator's wallet or username</Form.Label>
      <Form.Control
       type="search"
       placeholder="Paste wallet or username"
       autoFocus
       onInput={(e) => setQuery(e.target.value)}
      />
     </Form.Group>
     {isLoading && <p>Loading...</p>}
     <ul>
      {results.length > 0 &&
       results.map((user, index) => (
        <li key={index} className="my-2 search-hover" style={{ background: "transparent" }}>
         <a
          href={`profile?user=${!user.username ? user.wallet : user.username}`}
          style={{ color: "#fff" }}
          target="_blank"
         >
          <div className="text-left ml-5">
           <img
            src={domain + user.profile_photo}
            alt="Profile"
            width="50"
            className="mr-2"
            style={{ borderRadius: "50%" }}
           />
           {!user.username ? shortenAddress(user.wallet) : user.username}{" "}
           {user.verified === "yes" && <i className="fa fa-check-circle" aria-hidden="true" />}
          </div>
         </a>
        </li>
       ))}
     </ul>
    </Modal.Body>
   </Modal>
  </>
 );
};

export default Header;
