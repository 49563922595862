import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import axios from "axios";
import $ from "jquery";
import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 getQueryParam,
 getPercentage,
 showAlert,
} from "../../utils/helpers";
import "../styles/post-video.css";
const now = Math.floor(Date.now() / 1000);

function EditProfile() {
 const [video, setVideo] = useState({});

 const {
  address,
  token,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  user,
  getUser,
  domain,
 } = useContext(Context);
 const [categories, setCategories] = useState([]);
 const [category, setCategory] = useState("");
 const [title, setTitle] = useState("");
 const [isVR, setIsVR] = useState("");
 const [isPrivate, setIsPrivate] = useState("");

 const [description, setDescription] = useState("");
 const [tags, setTags] = useState("");
 const [coverPhoto, setCoverPhoto] = useState(null);
 const [x, setX] = useState("");
 const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
 const [tiktok, setTiktok] = useState("");
 const [instagram, setInstagram] = useState("");
 const [bio, setBio] = useState("");
 const [contentPrice, setContentPrice] = useState("");
 const [dob, setDob] = useState("");
 const [email, setEmail] = useState("");
 const [gender, setGender] = useState("");
 const [profilePhoto, setProfilePhoto] = useState(null);
 const [profilePhotoPrev, setProfilePhotoPrev] = useState(null);

 const [username, setUsername] = useState("");
 const [country, setCountry] = useState("");
 const [firstName, setFirstName] = useState("");
 const [surname, setSurname] = useState("");

 const [idType, setIdType] = useState("");
 const [idNumber, setIdNumber] = useState("");
 const [frontPrev, setFrontPrev] = useState(null);
 const [backPreview, setBackPreview] = useState(null);
 const [selfPreview, setSelfPreview] = useState(null);
 const [front, setFront] = useState(null);
 const [back, setBack] = useState(null);
 const [self, setSelf] = useState(null);

 const [uploadProgress, setUploadProgress] = useState(0);
 const [uploadProgressKYC, setUploadProgressKYC] = useState(0);

 useEffect(() => {
  if (Object.keys(user).length > 0) {
   setBio(user.bio);
   setContentPrice(user.content_price);
   setEmail(user.email);
   setGender(user.gender);
   setUsername(user.username);
   setInstagram(user.instagram != "null" ? user.instagram : "");
   setTiktok(user.tiktok != "null" ? user.tiktok : "");
   setX(user.x != "null" ? user.x : "");
  }
 }, [user]);

 const handleCoverPhotoChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   setCoverPhoto(file);
   setCoverPhotoPreview(URL.createObjectURL(file));
  }
 };

 const handleProfilePhotoChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   setProfilePhoto(file);
   setProfilePhotoPrev(URL.createObjectURL(file));
  }
 };

 const handleFrontPhotoChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   setFront(file);
   setFrontPrev(URL.createObjectURL(file));
  }
 };

 const handleBackPhotoChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   setBack(file);
   setBackPreview(URL.createObjectURL(file));
  }
 };

 const handleSelfPhotoChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   setSelf(file);
   setSelfPreview(URL.createObjectURL(file));
  }
 };

 const handleUpdate = async (e) => {
  e.preventDefault();
  if (!isConnected)
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });

  const formData = new FormData();
  formData.append("wallet", address);
  formData.append("bio", bio);
  if (user.is_creator === "yes") {
   formData.append("content_price", contentPrice);
  }
  formData.append("email", email);
  formData.append("current_email", user.email);

  formData.append("gender", gender);
  if (user.username == null) {
   formData.append("username", username);
  }
  if (coverPhoto != null) {
   formData.append("coverPhoto", coverPhoto);
  }
  if (profilePhoto != null) {
   formData.append("profilePhoto", profilePhoto);
  }
  formData.append("instagram", instagram);
  formData.append("tiktok", tiktok);
  formData.append("x", x);
  formData.append("action", "edit_profile");
  try {
   console.log("DATA", formData);

   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
     setUploadProgress(percentCompleted);
    },
   });

   if (response.data.success) {
    getUser(address);

    showAlert({
     title: "Successful!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "Sounds good",
    });
   } else {
    console.log("Error:", response);
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while updating the KYC. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
 };

 const handleKYCUpdate = async (e) => {
  e.preventDefault();
  const dobRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4})$/;

  if (!isConnected)
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });

  if (!dobRegex.test(dob)) {
   return showAlert({
    title: "Error!",
    text: "Please enter the date of birth in the format DD-MM-YYYY.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  const formData = new FormData();
  formData.append("wallet", address);
  formData.append("country", country);
  formData.append("first_name", firstName);
  formData.append("surname", surname);
  formData.append("dob", dob);
  formData.append("id_type", idType);
  formData.append("id_number", idNumber);
  formData.append("front", front);
  formData.append("back", back);
  formData.append("self", self);

  formData.append("action", "edit_kyc");
  try {
   console.log("DATA", formData);

   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
     setUploadProgressKYC(percentCompleted);
    },
   });

   if (response.data.success) {
    getUser(address);

    showAlert({
     title: "Successful!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "Sounds good",
    });
   } else {
    console.log("Error:", response);
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while updating the KYC. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="page-title">
       <div
        className="main-top"
        style={{
         backgroundImage: `url(${domain + user.cover_photo})`,
        }}
       >
        <div className="overlay-top-header" />
        <div className="content container" style={{ paddingBottom: "30px" }}>
         <h1>EDIT PROFILE DETAILS</h1>
         <div className="description">Use the form below to edit your profile.</div>
        </div>
       </div>
      </div>
      <div className="site-content layout-1">
       <div className="container">
        <div className="form-container">
         {!isConnected ? (
          <div className="alert-warning p-3 my-3 rounded">
           <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Please connect your
           wallet to be able to edit your profile.
          </div>
         ) : (
          <>
           <form onSubmit={handleUpdate}>
            <div className="row">
             <div className="col-md-12 text-center my-3">
              <h2>PROFILE DETAILS</h2>
             </div>
             <div className="col-md-4">
              <div className="form-group">
               <label htmlFor="username">Username</label>
               <input
                type="text"
                className="form-control"
                id="username"
                placeholder="Pick a username"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                required
                readOnly={user.username != null}
               />
              </div>
             </div>
             <div className="col-md-4">
              <div className="form-group">
               <label htmlFor="email">Email</label>
               <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
               />
              </div>
             </div>
             {user.is_creator == "yes" && (
              <div className="col-md-4">
               <div className="form-group">
                <label>How many v18plus to charge your fans per month</label>
                <input
                 type="text"
                 className="form-control"
                 placeholder="Enter amount"
                 onChange={(e) => setContentPrice(e.target.value)}
                 value={contentPrice}
                 required
                />
               </div>
              </div>
             )}
             <div className="col-md-12">
              <label>How do you identify?</label>
              <div className="row">
               <div className="col-md-4">
                <label htmlFor="Gmale" className="rcontainer">
                 Male
                 <input
                  id="Gmale"
                  type="radio"
                  name="gender"
                  value="male"
                  onChange={(e) => setGender(e.target.value)}
                  checked={gender === "male"}
                  required
                 />
                 <span className="rcheckmark"></span>
                </label>
               </div>
               <div className="col-md-4">
                <label htmlFor="Gfemale" className="rcontainer">
                 Female
                 <input
                  id="Gfemale"
                  type="radio"
                  name="gender"
                  value="female"
                  onChange={(e) => setGender(e.target.value)}
                  checked={gender === "female"}
                 />
                 <span className="rcheckmark"></span>
                </label>
               </div>

               <div className="col-md-4">
                <label htmlFor="Gcouple" className="rcontainer">
                 Couple
                 <input
                  id="Gcouple"
                  type="radio"
                  name="gender"
                  value="couple"
                  onChange={(e) => setGender(e.target.value)}
                  checked={gender === "couple"}
                 />
                 <span className="rcheckmark"></span>
                </label>
               </div>

               <div className="col-md-4">
                <label htmlFor="Gtrans" className="rcontainer">
                 Trans
                 <input
                  id="Gtrans"
                  type="radio"
                  name="gender"
                  value="trans"
                  onChange={(e) => setGender(e.target.value)}
                  checked={gender === "trans"}
                 />
                 <span className="rcheckmark"></span>
                </label>
               </div>

               <div className="col-md-4">
                <label htmlFor="Gnb" className="rcontainer">
                 Non-Binary
                 <input
                  id="Gnb"
                  type="radio"
                  name="gender"
                  value="non-binary"
                  onChange={(e) => setGender(e.target.value)}
                  checked={gender === "non-binary"}
                 />
                 <span className="rcheckmark"></span>
                </label>
               </div>

               <div className="col-md-4">
                <label htmlFor="Gprivate" className="rcontainer">
                 Private
                 <input
                  id="Gprivate"
                  type="radio"
                  name="gender"
                  value="private"
                  onChange={(e) => setGender(e.target.value)}
                  checked={gender === "private"}
                 />
                 <span className="rcheckmark"></span>
                </label>
               </div>
              </div>
             </div>

             <div className="col-md-12 my-4">
              <div className="form-group">
               <label htmlFor="videoDescription">About Me</label>
               <textarea
                className="form-control"
                id="videoDescription"
                style={{ height: "120px" }}
                placeholder="Tell fans something about you."
                value={bio}
                required
                onChange={(e) => setBio(e.target.value)}
                maxLength={300}
               />
               {bio.length} / 300.
              </div>
              <div className="row">
               <div className="col-md-4">
                <div className="form-group">
                 <label>Instagram link (optional)</label>
                 <input
                  type="url"
                  className="form-control"
                  placeholder="Instagram"
                  onChange={(e) => setInstagram(e.target.value)}
                  value={instagram}
                 />
                </div>
               </div>
               <div className="col-md-4">
                <div className="form-group">
                 <label>Tiktok link (optional)</label>
                 <input
                  type="url"
                  className="form-control"
                  placeholder="Tiktok"
                  onChange={(e) => setTiktok(e.target.value)}
                  value={tiktok}
                 />
                </div>
               </div>
               <div className="col-md-4">
                <div className="form-group">
                 <label>X link (optional)</label>
                 <input
                  type="url"
                  className="form-control"
                  placeholder="X (Twitter)"
                  onChange={(e) => setX(e.target.value)}
                  value={x}
                 />
                </div>
               </div>
              </div>
             </div>
             <div className="col-md-6 text-center">
              <div className="form-group">
               <label htmlFor="profilePhoto">
                <img
                 src={profilePhotoPrev || domain + user.profile_photo}
                 width={150}
                 alt="profile placeholder"
                />
               </label>
               <div className="custom-file">
                <input
                 type="file"
                 className="custom-file-input"
                 id="profilePhoto"
                 accept="image/*"
                 onChange={handleProfilePhotoChange}
                />
                <label className="custom-file-label" htmlFor="profilePhoto">
                 Choose profile photo
                </label>
               </div>
              </div>
             </div>

             <div className="col-md-6 text-center">
              <div className="form-group">
               <label htmlFor="coverPhoto">
                <img
                 src={coverPhotoPreview || domain + user.cover_photo}
                 width={150}
                 alt="cover placeholder"
                />
               </label>
               <div className="custom-file">
                <input
                 type="file"
                 className="custom-file-input"
                 id="coverPhoto"
                 accept="image/*"
                 onChange={handleCoverPhotoChange}
                />
                <label className="custom-file-label" htmlFor="coverPhoto">
                 Choose cover photo
                </label>
               </div>
              </div>
             </div>

             {uploadProgress > 0 && (
              <div className="col-md-12 my-4">
               <div className="progress">
                <div
                 className="progress-bar"
                 role="progressbar"
                 style={{ width: `${uploadProgress}%` }}
                 aria-valuenow={uploadProgress}
                 aria-valuemin="0"
                 aria-valuemax="100"
                >
                 {uploadProgress}%
                </div>
               </div>
              </div>
             )}

             <div className="col-md-12 text-center my-2">
              <button type="submit" className="btn btn-custom">
               Submit
              </button>
             </div>
            </div>
           </form>
           {user.verified !== "yes" && (
            <>
             <div className="w-100 my-5" style={{ height: "2px", background: "#fff" }}></div>
             {user.verified !== "pending" ? (
              <form onSubmit={handleKYCUpdate}>
               <div className="row">
                <div className="col-md-12 text-center my-3">
                 <h2>KYC DETAILS</h2>
                 <div className="alert-info p-3 my-3 rounded">
                  <i className="fa fa-info-circle" aria-hidden="true"></i> You must pass KYC
                  verification to be able to post videos and earn on this platform. Your KYC details
                  will not show on your public profile.
                 </div>
                </div>
                <div className="col-md-6">
                 <div className="form-group">
                  <label>Date of Birth (DD-MM-YYYY)</label>
                  <input
                   type="text"
                   className="form-control"
                   placeholder="DD-MM-YYYY"
                   onChange={(e) => setDob(e.target.value)}
                   value={dob}
                   required
                  />
                 </div>
                </div>
                <div className="col-md-6">
                 <div className="form-group">
                  <label>Country/Nationality</label>
                  <input
                   type="text"
                   className="form-control"
                   placeholder="Type country name"
                   onChange={(e) => setCountry(e.target.value)}
                   value={country}
                   required
                  />
                 </div>
                </div>
                <div className="col-md-6">
                 <div className="form-group">
                  <label>First Name</label>
                  <input
                   type="text"
                   className="form-control"
                   placeholder="Jane"
                   onChange={(e) => setFirstName(e.target.value)}
                   value={firstName}
                   required
                  />
                 </div>
                </div>
                <div className="col-md-6">
                 <div className="form-group">
                  <label>Surname</label>
                  <input
                   type="text"
                   className="form-control"
                   placeholder="John"
                   onChange={(e) => setSurname(e.target.value)}
                   value={surname}
                   required
                  />
                 </div>
                </div>
                <div className="col-md-6">
                 <div className="form-group">
                  <label>Method of verification</label>
                  <select onChange={(e) => setIdType(e.target.value)} required>
                   <option>Select ID type</option>

                   <option value="National ID">National ID</option>
                   <option value="Passport">Passport</option>
                   <option value="Drivers license">Drivers licence</option>
                  </select>
                 </div>
                </div>

                <div className="col-md-6">
                 <div className="form-group">
                  <label>{idType} number</label>
                  <input
                   type="text"
                   className="form-control"
                   placeholder="12345678"
                   onChange={(e) => setIdNumber(e.target.value)}
                   value={idNumber}
                   required
                  />
                 </div>
                </div>

                <div className="col-md-4 text-center">
                 <div className="form-group">
                  <label htmlFor="front">
                   <img
                    src={frontPrev || "assets/images/front.png"}
                    width={150}
                    alt="front id placeholder"
                   />
                  </label>
                  <div className="custom-file">
                   <input
                    type="file"
                    className="custom-file-input"
                    id="front"
                    accept="image/*"
                    onChange={handleFrontPhotoChange}
                    required
                   />
                   <label className="custom-file-label" htmlFor="front">
                    Upload {idType} front side
                   </label>
                  </div>
                 </div>
                </div>

                <div className="col-md-4 text-center">
                 <div className="form-group">
                  <label htmlFor="back">
                   <img
                    src={backPreview || "assets/images/back.png"}
                    width={150}
                    alt="Back id placeholder"
                   />
                  </label>
                  <div className="custom-file">
                   <input
                    type="file"
                    className="custom-file-input"
                    id="back"
                    accept="image/*"
                    onChange={handleBackPhotoChange}
                    required
                   />
                   <label className="custom-file-label" htmlFor="back">
                    Upload {idType} back side
                   </label>
                  </div>
                 </div>
                </div>

                <div className="col-md-4 text-center">
                 <div className="form-group">
                  <label htmlFor="self">
                   <img
                    src={selfPreview || "assets/images/self.png"}
                    width={150}
                    alt="selfie id placeholder"
                   />
                  </label>
                  <div className="custom-file">
                   <input
                    type="file"
                    className="custom-file-input"
                    id="self"
                    accept="image/*"
                    onChange={handleSelfPhotoChange}
                    required
                   />
                   <label className="custom-file-label" htmlFor="self">
                    Upload s selfie of you holding your {idType}
                   </label>
                  </div>
                 </div>
                </div>

                {uploadProgressKYC > 0 && (
                 <div className="col-md-12 my-4">
                  <div className="progress">
                   <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                     width: `${uploadProgressKYC}%`,
                    }}
                    aria-valuenow={uploadProgressKYC}
                    aria-valuemin="0"
                    aria-valuemax="100"
                   >
                    {uploadProgressKYC}%
                   </div>
                  </div>
                 </div>
                )}

                <div className="col-md-12 text-center my-2">
                 <button type="submit" className="btn btn-custom">
                  Submit
                 </button>
                </div>
               </div>
              </form>
             ) : (
              <div className="text-center">
               <h2>KYC IN REVIEW</h2>
               <div className="alert-warning p-3 my-3 rounded">
                <i className="fa fa-info-circle" aria-hidden="true"></i> Your KYC details are still
                being reviewed. You will know the status soon.
               </div>
              </div>
             )}
            </>
           )}
          </>
         )}
        </div>
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default EditProfile;
