import React, { useState, useContext, useEffect } from "react";
import { Context } from "../context/contex";
import { formatNumber, shortenAddress, copy } from "../utils/helpers";
import TabBar from "./Tabbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
const Footer = () => {
 const [topCreators, setTopCreators] = useState([]);
 const [topViewers, setTopViewers] = useState([]);

 const { settings, tokenPrice, apiURL, domain } = useContext(Context);
 const getTopCreators = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_all_top_creators" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setTopCreators(data);
    // console.log("CREATORS", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getTopCreators();
 }, []);

 const getTopViewers = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_top_viewers" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setTopViewers(data);
    //  console.log("VIEWERS", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getTopViewers();
 }, []);

 return (
  <>
   <footer className="site-footer layout-1 footer-stop">
    <div className="footer-sidebars">
     <div className="container">
      <div className="row">
       <div className="col-12">
        <h3 className="title text-white">TOP 20 CREATORS</h3>
        {topCreators.length > 0 && (
         <Swiper
          navigation={true}
          modules={[Autoplay, Navigation]}
          autoplay
          //effect="fade"
          // slidesPerView={3}
          loop={true}
          breakpoints={{
           640: {
            slidesPerView: 1,
            spaceBetween: 20,
           },
           768: {
            slidesPerView: 3,
            spaceBetween: 30,
           },
           1024: {
            slidesPerView: 5,
            spaceBetween: 30,
           },
          }}
          className="my-5 list-posts"
         >
          {topCreators.length > 0 &&
           topCreators.map((c, i) => (
            <SwiperSlide key={`p-video${i}`}>
             {" "}
             <div style={{ display: "flex", alignItems: "center", gap: 10 }} className="">
              <div>
               <a href={`profile?user=${c.username ? c.username : c.wallet}`}>
                <img
                 src={`${domain}${c.profile_photo}`}
                 alt="IMG"
                 style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                 }}
                />
               </a>
              </div>
              <div className="text">
               <h4 className="">
                <a
                 className="text-white"
                 href={`profile?user=${c.username ? c.username : c.wallet}`}
                >
                 {c.username ? c.username : shortenAddress(c.wallet)}{" "}
                 {c.verified === "yes" && (
                  <i className="fa fa-check-circle text-success" aria-hidden="true" />
                 )}
                </a>

                <div style={{ color: "#aaa", fontSize: "10px" }}>
                 ${formatNumber(tokenPrice * c.total_earned)}
                </div>
               </h4>
              </div>
             </div>
            </SwiperSlide>
           ))}
         </Swiper>
        )}
       </div>
       <div className="col-12 text-center text-white">
        <p>
         We are creating the first adult digital community where users – viewers and content
         creators – EARN REWARDS for the interactions they generate, in an environment they already
         tend to visit almost daily.
        </p>
       </div>
       <div className="text-center">
        <div className="wpb_text_column">
         <p>
          <b className="text-white">18plus Contract Address</b>
         </p>
        </div>
        <div
         className="yikes-easy-mc-form layout-footer"
         style={{
          display: "flex",
          gap: 5,
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          margin: "20px",
         }}
        >
         <div>
          <input
           style={{ width: "100% !important", background: "#333", color: "#fff" }}
           type="text"
           placeholder="Contract"
           value={settings.token || ""}
           readOnly
          />
         </div>

         <i
          style={{ cursor: "pointer" }}
          onClick={() => copy(settings.token)}
          className="fa fa-clone text-danger"
          aria-hidden="true"
         ></i>
        </div>
        <aside className="widget widget_thim_layout_builder">
         <div className="bp-element bp-element-social-links vblog-layout-footer">
          <div className="wrap-element mb-4">
           <a href="https://t.me/ai18pluscoin" className="social-item" target="_blank">
            <img src="assets/images/telegram.png" className="ion" height={30} />
           </a>
           <a href="https://x.com/modelandai" className="social-item" target="_blank">
            <img src="assets/images/twitter.png" className="ion" height={30} />
           </a>
           <a
            href="https://www.facebook.com/profile.php?id=61570003961216"
            className="social-item"
            target="_blank"
           >
            <img src="assets/images/facebook.png" className="ion" height={30} />
           </a>
           <a href="https://www.instagram.com/modeland.ai" className="social-item" target="_blank">
            <img src="assets/images/instagram.png" className="ion" height={30} />
           </a>
           <a href="https://www.tiktok.com/@modeland.ai" className="social-item" target="_blank">
            <img src="assets/images/tiktok.png" className="ion" height={30} />
           </a>
           <a href="https://www.youtube.com/@modeland" className="social-item" target="_blank">
            <img src="assets/images/youtube.png" className="ion" height={30} />
           </a>
          </div>
         </div>
        </aside>
       </div>
      </div>
     </div>
    </div>
    <div className="copyright-area">
     <div className="container">
      <div className="thim-1-col">
       <div className="copyright-text">Copyright 2024. Ai18Plus</div>
      </div>
     </div>
    </div>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
   </footer>
   <TabBar />
  </>
 );
};

export default Footer;
