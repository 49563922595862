import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Swap2 from "../../components/face-swap2";

import { Context } from "../../context/contex";
import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 getQueryParam,
 showAlert,
} from "../../utils/helpers";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import "./services.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

function NewSwap() {
 const { domain } = useContext(Context);

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="page-title">
       <div
        className="main-top"
        style={{
         backgroundImage: "url(assets/images/nft-bg4.jpeg)",
         backgroundAttachment: "fixed",
        }}
       >
        <div className="overlay-top-header" />
        <div className="content container" style={{ paddingBottom: "30px" }}>
         <div className="row d-flex align-items-center">
          <div className="col-md-6">
           <h1 style={{ fontWeight: 700 }}>LET'S FACE-SWAP</h1>
           <div className="description" style={{ color: "#fff" }}>
            <b>Tips:</b>
            <br />
            To get best on your Ai face swap, please ensure the quality of the video you want to
            reface has a high resolution with clear faces.
           </div>
          </div>
          <div className="col-md-6">
           <video
            controls
            width="100%"
            // height="660px"

            onError={(e) => console.error("Error loading video:", e)}
            className="mt-3"
           >
            <source src={domain + "uploads/explainer/face-swap.mp4"} type="video/mp4" />
            Your browser does not support the video tag.
           </video>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="site-content layout-1">
       <div className="container">
        <Swap2 />
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>

   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default NewSwap;
