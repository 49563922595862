import React, { useContext, useEffect, useState } from "react";
import { Container, Carousel, Tab, Tabs, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Context } from "../context/contex";

const Slider = () => {
 const [slides, setSlides] = useState({ slides: [] });
 const { apiURL, domain } = useContext(Context);

 const getSlides = async () => {
  // if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_slides",
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (!data.success) {
    console.error("Errors:", data.message);
   } else {
    setSlides(data.slides);
    console.log("SLIDES:", data.slides);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getSlides();
 }, []);

 return (
  <>
   {slides.length > 0 && (
    <div className="carousel-container">
     <Carousel indicators={false} controls={true} interval={3000} fade={true}>
      {slides.map((item) => (
       <Carousel.Item key={item.id}>
        <a href={item.link !== "none" ? item.link : "#"}>
         <img className="d-block w-100 carousel-image" src={domain + item.image} />
        </a>
       </Carousel.Item>
      ))}
     </Carousel>
    </div>
   )}
  </>
 );
};

export default Slider;
