import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import { ReadContract } from "../../utils/readContract";
import "../styles/post-video.css";
import "./profile.css";
import usePagination from "../../components/usePagination";
import Pagination2 from "../../components/Pagination2";
import { Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 showAlert,
 searchArray,
 searchArrayBool,
 generateNonce,
} from "../../utils/helpers";
import axios from "axios";
import Post from "../../components/Post";
import Picker from "emoji-picker-react";

import { useQueryParam } from "../../components/useQueryParam";

import { ethers, parseUnits } from "ethers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

function Inbox() {
 const [userVideos, setUserVideos] = useState([]);
 const [userFans, setUserFans] = useState([]);
 const [userCreators, setUserCreators] = useState([]);
 const [pagination, setPagination] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [profile, setProfile] = useState({});
 const [profileOwner, setProfileOwner] = useState(false);
 const [canClaim, setCanClaim] = useState(false);
 const [text, setText] = useState("");
 const [inboxText, setInboxText] = useState("");
 const [vtype, setVtype] = useState("all");
 const [videoCount, setVideoCount] = useState(0);
 const [photoCount, setPhotoCount] = useState(0);

 const [unread, setUnread] = useState(0);
 const [alerts, setAlerts] = useState([]);
 const [isFan, setIsFan] = useState(false);
 const [creditGiftProfile, setCreditGiftProfile] = useState("");
 const [creditGift, setCreditGift] = useState({});

 const [inboxImages, setInboxImages] = useState([]);

 const [images, setImages] = useState([]);
 const [senders, setSenders] = useState([]);
 const [tickets, setTickets] = useState([]);
 const [ticket, setTicket] = useState([]);
 const [activeTicket, setActiveTicket] = useState({});
 const [conversation, setConversation] = useState([]);
 const [activeChat, setActiveChat] = useState({});
 const [comments, setComments] = useState({});
 const [emojiPickers, setEmojiPickers] = useState(false);
 const [param, setParam] = useState(null);
 const query = useQueryParam("user");

 const {
  address,

  isConnected,
  tokenBalance,
  apiURL,
  user,
  setLoading,
  settings,
  withdraw,
  domain,
  tokenPrice,
  getUser,
 } = useContext(Context);

 useEffect(() => {
  if (!query) {
   setParam(null);
  } else {
   setParam(query);
  }
 }, [query]);

 const sendMessage = async () => {
  if (address === profile.wallet) {
   showAlert({
    title: "Error!",
    text: "You cannot start a conversation with yourself.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  const formData = new FormData();
  formData.append("action", "send_message");
  formData.append("sender", address);
  formData.append("receiver", profile.wallet);
  formData.append("chat", text);

  images.forEach((image, index) => {
   formData.append(`files[]`, image);
  });

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });

  if (response.data.success) {
   setText("");
   setImages([]);
   // fetchMessages();
   console.log("sent", response.data);
  } else {
   showAlert({
    title: "Error!",
    text: "Message sending failed",
    icon: "error",
    confirmButtonText: "Ok",
   });
   console.log("not sent", response);
  }
 };

 const sendMessageInbox = async () => {
  if (address === activeChat.sender) {
   showAlert({
    title: "Error!",
    text: "You cannot start a conversation with yourself.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  const formData = new FormData();
  formData.append("action", "send_message");
  formData.append("sender", address);
  formData.append("receiver", activeChat.sender);
  formData.append("chat", inboxText);

  inboxImages.forEach((image, index) => {
   formData.append(`files[]`, image);
  });

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });

  if (response.data.success) {
   setInboxText("");
   setInboxImages([]);
   fetchMessagesInbox();
   console.log("sent", response.data);
  } else {
   showAlert({
    title: "Error!",
    text: "Message sending failed",
    icon: "error",
    confirmButtonText: "Ok",
   });
   console.log("not sent", response);
  }
 };

 useEffect(() => {
  if (Object.keys(activeChat).length == 0) {
   setConversation([]);
   return;
  }
  fetchMessagesInbox();
 }, [address]);

 const canWithdraw = async () => {
  try {
   const contract = await ReadContract();

   const time = await contract.canWithdraw();
   //  console.log("ENDED", time);

   setCanClaim(time);
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  canWithdraw();
 }, []);

 const getProfile = async (wallet) => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_user_info", wallet: wallet }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setProfile(data[0]);

    console.log("Fetched profile:", data[0]);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!param) {
   setProfile({});
  } else {
   getProfile(param);
  }
 }, [param, user]);

 const getUserVideos = async (vtype = "all", page = 1, limit = 16) => {
  // if (!param ||  == null) return;
  setLoading(true);
  try {
   const details = {
    action: "get_user_videos",
    wallet: param,
    page: page,
    limit: limit,
    vtype: vtype,
   };
   if (profile.username != null) {
    details.is_username = "yes";
   }
   if (isConnected && address) {
    details.user = address;
   }
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserVideos(data.records);
    setPagination(data.pagination);

    setPhotoCount(data.summary.total_photos);
    setVideoCount(data.summary.total_videos);

    console.log("All videos:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  if (!param || param == null) return;
  getUserVideos(vtype);
 }, [param, profile, vtype, address]);

 const getUserAlerts = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_notifications",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setAlerts(data);
    console.log("ALERTS:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) return;
  getUserAlerts(address);
 }, [address, isConnected]);

 const getUserFans = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_user_fans",
    wallet: wallet,
   };
   if (profile.username != null) {
    details.is_username = "yes";
   }
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserFans(data);
    //  console.log("All fans:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!param || param == null) return;
  getUserFans(param);
 }, [param, profile]);

 const getUserCreators = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_user_creators",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserCreators(data);
    //  console.log("All fans:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!address || !isConnected) return;
  getUserCreators(address);
 }, [address, isConnected]);

 useEffect(() => {
  if (isConnected && profile.wallet === address) {
   setProfileOwner(true);
  } else {
   setProfileOwner(false);
  }
 }, [isConnected, address, profile]);

 const updateRead = async (senderId, receiverId) => {
  // console.log("THE", chat);
  const formData = new FormData();
  formData.append("sender", senderId);
  formData.append("receiver", receiverId);
  formData.append("action", "update_unread_chat");

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "application/x-www-form-urlencoded",
   },
  });
  // console.log("THE", response);

  if (response.data.success) {
   //setConversation(response.data.messages);
   // setActiveChat(chat);
   console.log("UPDATE", response.data.status);
  }
 };

 const fetchMessages = async (senderId, receiverId, chat) => {
  // console.log("THE", chat);
  const formData = new FormData();
  formData.append("sender_id", senderId);
  formData.append("receiver_id", receiverId);
  formData.append("action", "get_messages");

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "application/x-www-form-urlencoded",
   },
  });
  // console.log("THE", response);

  if (response.data.success) {
   setConversation(response.data.messages);
   setActiveChat(chat);
   updateRead(senderId, receiverId);
   fetchSenders();
   //   console.log("THESENDER", response);
  }
 };

 const fetchMessagesInbox = async () => {
  // console.log("THE", chat);
  const formData = new FormData();
  formData.append("sender_id", activeChat.sender);
  formData.append("receiver_id", address);
  formData.append("action", "get_messages");

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "application/x-www-form-urlencoded",
   },
  });
  // console.log("THE", response);

  if (response.data.success) {
   setConversation(response.data.messages);
   //  updateRead(senderId, receiverId);
   //  setActiveChat(chat);
   //   console.log("THESENDER", response);
  }
 };

 const fetchSenders = async () => {
  if (!isConnected) {
   return;
  }

  const formData = new FormData();
  formData.append("receiver_wallet", address);
  formData.append("action", "get_chat_head");

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
   });

   if (response.data.length > 0) {
    console.log("senders:", response.data);

    setSenders(response.data);
   } else {
    setSenders([]);
   }
  } catch (error) {
   console.error("Error:", error);
  }
 };

 useEffect(() => {
  fetchSenders();
 }, [isConnected, address]);

 useEffect(() => {
  const fetchUnread = async () => {
   if (!isConnected) {
    return;
   }

   const formData = new FormData();
   formData.append("receiver", address);
   formData.append("action", "get_unread_chat");

   try {
    const response = await axios.post(apiURL, formData, {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    });

    if (response.data.success) {
     //  console.log("UNREAD:", response.data);

     setUnread(response.data.unread);
    } else {
     // console.log("AUNREAD:", response.data);

     setUnread(0);
    }
   } catch (error) {
    console.error("Error:", error);
   }
  };

  fetchUnread();
  const interval = setInterval(() => {
   fetchUnread();
  }, 3000);
  return () => clearInterval(interval);
 }, [isConnected, address]);

 useEffect(() => {
  if (userFans.length === 0) {
   return;
  }
  setIsFan(searchArray(userFans, address));
 }, [userFans]);

 const handleSubscribeProfile = async (creator, amount) => {
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (+user.credits < amount) {
   return showAlert({
    title: "ERROR!",
    text: "Insufficient v18plus balance. Top up v18plus and try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "add_fan",
     creator: creator,
     fan: address,
     amount: amount,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    await getUserVideos(vtype);
    await getUserFans(param);
    await getUser(address);
    showAlert({
     title: "SUCCESSFUL!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    //  setIsFan(true);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 const sendGiftProfile = async () => {
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (!creditGiftProfile) {
   return showAlert({
    title: "ERROR!",
    text: "Enter v18plus amount.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (+user.credits < +creditGiftProfile) {
   return showAlert({
    title: "ERROR!",
    text: "Insufficient v18plus balance. Top up v18plus and try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "gift_credit",
     from: address,
     to: profile.wallet,
     amount: creditGiftProfile,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    await getUser(address);
    showAlert({
     title: "SUCCESSFUL!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    //  setIsFan(true);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 const toggleEmojiPicker = () => {
  setEmojiPickers((prev) => !prev);
 };

 const onEmojiClick = (event, emojiObject) => {
  const emoji = event.emoji;
  setInboxText((prev) => prev + emoji);
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    {isConnected && (
     <div id="main-content" style={{ background: "#1e1e1e" }}>
      <div className="content-area">
       <div className="site-content layout-1">
        <div className="container mt-5">
         {/* INBOX content */}
         <div className="chat-container">
          <div className="chat-sidebar">
           <div className="chat-sidebar-header">
            <h3>Inbox</h3>
           </div>
           <div className="chat-sidebar-content">
            {senders.length > 0 ? (
             senders.map((s, i) => (
              <div
               key={i}
               className={`chat-user ${activeChat.sender === s.sender && "hover"}`}
               onClick={() => fetchMessages(s.sender, address, s)}
              >
               <img src={domain + s.profile_photo} alt="User Avatar" className="chat-avatar" />
               <div className="chat-user-info">
                <p className="chat-username">
                 {!s.username ? shortenAddress(s.sender) : s.username}{" "}
                 {s.verified === "yes" && <i className="fa fa-check-circle" aria-hidden="true" />}
                 {s.is_read === 0 && (
                  <sup>
                   <span className="badge badge-danger">NEW</span>
                  </sup>
                 )}
                </p>
                <p className="chat-last-message">Since {formatLocalTime(s.add_date)}</p>
               </div>
              </div>
             ))
            ) : (
             <div className="text-center">
              <img src="assets/images/fly.gif" height={100} />
              <p>You have no messages yet...</p>
             </div>
            )}

            {/* Add more chat users as needed */}
           </div>
          </div>
          <div className="chat-main">
           <div className="chat-header">
            {Object.keys(activeChat).length > 0 && (
             <>
              <img
               src={domain + activeChat.profile_photo}
               alt="Chat User Avatar"
               className="chat-avatar"
              />
              <p className="chat-username">
               {" "}
               {!activeChat.username ? shortenAddress(activeChat.sender) : activeChat.username}{" "}
               {activeChat.verified === "yes" && (
                <i className="fa fa-check-circle" aria-hidden="true" />
               )}
              </p>
             </>
            )}
           </div>

           <div className="chat-content">
            {conversation.length > 0 &&
             conversation.map((msg, index) => (
              <div
               key={index}
               className={`chat-message ${
                msg.receiver === address ? "chat-message-sent" : "chat-message-received"
               }`}
              >
               {msg.message && <p>{msg.message}</p>}
               {msg.images.length > 0 &&
                msg.images.map((image, imgIndex) => (
                 <div key={imgIndex}>
                  <img
                   className="my-2"
                   src={domain + image}
                   alt={`image-${imgIndex}`}
                   height={200}
                  />
                 </div>
                ))}
               <span className="chat-timestamp">{formatLocalTime(msg.add_date)}</span>
              </div>
             ))}

            {/* Add more chat messages as needed */}
           </div>
           {conversation.length > 0 && (
            <>
             {inboxImages.length > 0 && (
              <div className="ml-2">
               Added {inboxImages.length} {inboxImages.length === 1 ? "photo" : "photos"}{" "}
               <i
                style={{ cursor: "pointer" }}
                className="fa fa-times text-danger"
                aria-hidden="true"
                onClick={() => setInboxImages([])}
               ></i>
              </div>
             )}
             <div className="chat-footer">
              <input
               type="text"
               className="chat-input"
               placeholder="Type a message..."
               value={inboxText}
               onChange={(e) => setInboxText(e.target.value)}
              />
              <input
               type="file"
               multiple
               onChange={(e) => setInboxImages([...inboxImages, ...e.target.files])}
               id="inbox-images"
               className="file-input"
              />
              <label className="custom-file-label" htmlFor="inbox-images">
               <i className="fa fa-camera" aria-hidden="true"></i>
              </label>
              <div
               style={{
                cursor: "pointer",
                fontSize: "25px",
               }}
               onClick={() => toggleEmojiPicker()}
               className="mx-3"
              >
               <i className="fa fa-smile-o text-primary" aria-hidden="true"></i>
              </div>
              {emojiPickers && (
               <div style={{ position: "absolute", top: "30%", left: "50px" }}>
                <Picker onEmojiClick={(event, emojiObject) => onEmojiClick(event, emojiObject)} />
               </div>
              )}
              <button onClick={sendMessageInbox} className="chat-send-button">
               Send
              </button>
             </div>
            </>
           )}
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    )}
    <Footer />
   </div>
  </div>
 );
}

export default Inbox;
