import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import {
 shortenAddress,
 handleStopAndReset,
 playExplainer,
 formatNumber,
} from "../../utils/helpers";
import axios from "axios";

import Pagination from "../../components/Pagination";

function Videos() {
 const { apiURL, domain, setLoading } = useContext(Context);

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }} className="pt-5">
     <div className="content-area">
      <div className="carousel-container">
       <img
        className="d-block w-100 carousel-image"
        src={domain + "assets/images/slides/video-template.jpg"}
       />
      </div>
      <div className="row my-5" style={{ display: "flex", alignItems: "center" }}>
       <div className="col-md-4">
        <div style={{ border: "2px #000 solid" }}></div>
       </div>
       <div className="col-md-4 px-5">
        <div className="text-center">
         <h1 style={{ fontWeight: "700", fontSize: "70px", fontFamily: "Jura" }}>COMING SOON</h1>
        </div>
       </div>
       <div className="col-md-4">
        <div style={{ border: "2px #000 solid" }}></div>
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>
  </div>
 );
}

export default Videos;
