import React, { useState } from "react";
import "./TabBar.css";

const TabBar = () => {
 const [activeMenu, setActiveMenu] = useState(null);

 const handleMenuClick = (menu) => {
  setActiveMenu(activeMenu === menu ? null : menu);
 };

 return (
  <div className="tab-bar-container">
   {activeMenu === "creators" && (
    <ul className="sub-menu">
     <li>
      <a href="/creators">Browse Creators</a>
     </li>
     <li>
      <a href="/become-creator">Become A Creator</a>
     </li>
     <li>
      <a href="/my-ai-models">My Ai Models</a>
     </li>
     <li>
      <a href="/face-swap">Face Swap</a>
     </li>
    </ul>
   )}
   {activeMenu === "watch" && (
    <ul className="sub-menu">
     <li>
      <a href="/videos">Videos</a>
     </li>
     <li>
      <a href="/live">Live Stream</a>
     </li>
    </ul>
   )}
   {activeMenu === "nfts" && (
    <ul className="sub-menu">
     <li>
      <a href="/marketplace">Open Marketplace</a>
     </li>
     <li>
      <a href="/onchain-nft">NFT IDs</a>
     </li>
     <li>
      <a href="/profile-nft">Invest in a Creator</a>
     </li>
     <li>
      <a href="/create-nft">Mint your NFT</a>
     </li>
    </ul>
   )}

   {activeMenu === "about" && (
    <ul className="sub-menu">
     <li>
      <a href="https://ai18pluscoin.com/about-us/" target="_blank">
       About Us
      </a>
     </li>
     <li>
      <a target="_blank" href="https://ai18pluscoin.com/">
       COIN18+
      </a>
     </li>
     <li>
      <a target="_blank" href="https://blog.modeland.ai/">
       Blog
      </a>
     </li>
    </ul>
   )}

   <div className="tab-bar">
    <ul className="tab-bar-menu">
     <li className="tab-bar-item">
      <i className="fa fa-home" aria-hidden="true"></i>
      <p></p>
      <a href="/">Home</a>
     </li>

     <li className="tab-bar-item" onClick={() => handleMenuClick("creators")}>
      <span>
       <i className="fa fa-users" aria-hidden="true"></i>
       <p></p> Creators
      </span>
     </li>
     <li className="tab-bar-item" onClick={() => handleMenuClick("watch")}>
      <span>
       <i className="fa fa-play-circle-o" aria-hidden="true"></i>
       <p></p> Videos
      </span>
     </li>

     <li className="tab-bar-item" onClick={() => handleMenuClick("nfts")}>
      <span>
       <i className="fa fa-picture-o" aria-hidden="true"></i>
       <p></p> Marketplace
      </span>
     </li>

     <li className="tab-bar-item" onClick={() => handleMenuClick("about")}>
      <span>
       <i className="fa fa-book" aria-hidden="true"></i>
       <p></p> About
      </span>
     </li>
    </ul>
   </div>
  </div>
 );
};

export default TabBar;
